<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Revisión de facturas compras </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Cuentas por pagar compras" icon="currency-usd-off">
        <PurchasesRevisionList />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import PurchasesRevisionList from "../components/PurchasesRevisionList.vue";
export default {
  name: "PurchasesRevisionLists",
  components: {
    PurchasesRevisionList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "purchasesRevision");
  },
  async created() {
    let allPromises = [];
    allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
    allPromises.push(this.$store.dispatch("GETBANKS"));
    if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
    }
    allPromises.push(
      this.$store.dispatch("GETEXPENSES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
      })
    );
    if (this.$store.getters.PAYMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    }
    allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
